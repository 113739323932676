<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay
          :show="loader"
          rounded="sm"
          variant="dark"
          :opacity="0.1"
        >
          <b-card title="Update Brand">
            <validation-observer ref="updateBrandValidator">
              <b-form @submit.prevent="onSubmit">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Name"
                      label-for="name"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="form.name"
                          name="name"
                          placeholder="Enter brand name"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Image"
                      label-for="image"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="image"
                      >
                        <b-form-file
                          id="image"
                          v-model="form.image"
                          accept=".jpg, .png, .gif .svg"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Category"
                      label-for="category"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="category"
                        rules="required"
                      >
                        <vSelect
                          v-model="form.category"
                          multiple
                          label="name"
                          placeholder="Please select category"
                          :options="options"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        type="submit"
                      >
                        Update
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import { createNamespacedHelpers } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const mobileTabletModule = createNamespacedHelpers('mobileTablet')

export default {
  data() {
    return {
      loader: false,
      form: {
        name: '',
        category: [],
        image: null,
      },
      options: [],
      brandInfo: null,
    }
  },
  async mounted() {
    this.loader = true
    await this.getCategoriesAndBrand()
    this.loader = false
  },
  methods: {
    ...mobileTabletModule.mapActions(['SHOW_ALL_CATEGORY', 'FETCH_SINGLE_BRAND', 'UPDATE_BRAND']),

    async getCategoriesAndBrand() {
      try {
        this.loader = true
        const resp = await this.SHOW_ALL_CATEGORY()
        resp.forEach(element => {
          this.options.push(element)
        })
        // Fetch Brand
        this.brandInfo = await this.FETCH_SINGLE_BRAND(this.$route.params.id)
        this.form.name = this.brandInfo.name
        this.brandInfo.mobile_brand_categories.forEach(element => {
          this.options.forEach(cat => {
            if (cat.id === element.mobile_category_id) { this.form.category.push(cat) }
          })
        })
        this.loader = false
      } catch (error) {
        this.loader = false
      }
    },

    onSubmit() {
      this.$refs.updateBrandValidator.validate().then(async success => {
        if (success) {
          try {
            this.loader = true
            const formData = new FormData()
            if (this.brandInfo.name !== this.form.name) {
              formData.append('name', this.form.name)
            }
            this.form.category.forEach((element, i) => {
              formData.append(`mobile_category_id[${i}]`, element.id)
            })
            if (this.form.image) {
              formData.append('image', this.form.image)
            }
            const resp = await this.UPDATE_BRAND({ id: this.$route.params.id, data: formData })

            if (resp) {
              this.$nextTick(() => {
                this.$refs.updateBrandValidator.reset()
              })
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Brand Updated!',
                  icon: 'checkIcon',
                  variant: 'success',
                  text: 'The brand has been updated successfully!',
                },
              })
              this.$router.push('/mobile-tablet/brands')
            }
            this.loader = false
          } catch (error) {
            this.loader = false
          }
        }
      })
    },
  },
}
</script>
